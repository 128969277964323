<template>
    <div class = "col-right">
        <a-card>
            <div slot = "title">
                <div class = "page-title">
                    <div class = "item-title">商品变价记录</div>
                    <div class = "item-sub">仅展示我的商品库商品变价记录，最多可查询近三个月的记录。</div>
                </div>
            </div>
            <div class = "search-form">
                <a-form class = "ant-advanced-search-form" :form = "form" @submit = "handleSearch">
                  <a-row>
                    <a-col
                        :span = "8"
                    >
                      <a-form-item label = "商品名称">
                        <a-input />
                      </a-form-item>
                    </a-col>
                    <a-col
                        :span = "8"
                    >
                      <a-form-item label = "商品编号">
                        <a-input />
                      </a-form-item>
                    </a-col>
                    <a-col
                        :span = "8"
                    >
                      <a-form-item label = "规格编号">
                        <a-input />
                      </a-form-item>
                    </a-col>
                    <a-col
                        :span = "8"
                    >
                     <a-form-item
                         label = "变价时间"
                     >
                                        <a-range-picker @change = "onChange" style = "width: 200px"  :placeholder="['开始日期', '结束日期']" />
                                    </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span = "24" :style = "{ textAlign: 'left' }">
                      <a-button type = "primary" html-type = "submit">
                        查询
                      </a-button>
                      <a-button :style = "{ marginLeft: '8px' }" @click = "handleReset">
                        重置
                      </a-button>
                    </a-col>
                  </a-row>
                </a-form>

            </div>
        </a-card>
        <a-card>
            <a-table :columns="columns" :data-source="data" :scroll="{ x: true }">
             <div slot = "goods_title" slot-scope="text, record">
                  <span><div  class="name-col ng-star-inserted" style="display: flex; white-space: normal; cursor: pointer;"><img  :src="record.goods_img"><dl ><dt >{{ record.goods_title }}</dt><dd >规格：1件装 | 规格编号：1426079256471523330</dd><dd >商品编号：1426079256194699266</dd></dl></div></span>
              </div>
          </a-table>
        </a-card>
    </div>
</template>

<script>
const data = [];
for (let i = 0; i < 100; i++) {
    data.push({
        key: i,
        goods_title: `Edrward ${i}`,
        goods_img: 'https://scce-cos-prd.obs.cn-south-1.myhuaweicloud.com/260489546f2945d9905c0d7185f000b3.jpg',
        old_price: 32,
        new_price: 32,
        old_market_price: 32,
        new_market_price: 32,
        created_at: '2022-04-19 15:46:20',
    });
}

export default {
    name: "goods-offer",
    data() {
        return {
            form: this.$form.createForm(this, {name: 'advanced_search'}),
            columns: [
                { title: '商品', width: 420, dataIndex: 'goods_title', key: 'goods_title', scopedSlots: {customRender: 'goods_title'}, },
                { title: '旧分销价', width: 120, dataIndex: 'old_price', key: 'old_price' },
                { title: '新分销价', width: 120, dataIndex: 'new_price', key: 'new_price' },
                { title: '旧建议零售价(元)', width: 120, dataIndex: 'old_market_price', key: 'old_market_price' },
                { title: '新建议零售价(元)', width: 120, dataIndex: 'new_market_price', key: 'new_market_price' },
                { title: '变价时间', width: 120, dataIndex: 'created_at', key: 'created_at' },
            ],
            data
        }
    },
    methods: {
        handleReset() {
        },
        handleSearch() {
        }
    }
}
</script>

<style lang = "scss" scoped>
@import "../../assets/css/common";

.page-title {
    display: flex;
    align-items: center;

    .item-sub {
        font-size: 12px;
        color: #999;
        padding-left: 10px;
        flex: 1;
    }
}
.ant-table td { white-space: nowrap; }

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

#components-form-demo-advanced-search .ant-form {
    max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
.name-col {
    img {
        display: block;
        width: 60px;
        height: 60px;
    }
    dl {
        margin: 0;
        list-style: none;
        padding: 0 0 0 10px;
        flex: 1;
        width: 0;
        dt {
            font-size: 14px;
            line-height: 24px;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        dd {
            line-height: 18px;
            font-size: 12px;
            color: #666;
        }
    }
}

</style>